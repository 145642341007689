import { render, staticRenderFns } from "./GamePagePromoSection.htm?vue&type=template&id=fe787b72&scoped=true&"
import script from "./GamePagePromoSection.js?vue&type=script&lang=js&"
export * from "./GamePagePromoSection.js?vue&type=script&lang=js&"
import style0 from "./GamePagePromoSection.scss?vue&type=style&index=0&id=fe787b72&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fe787b72",
  null
  
)

export default component.exports