import ScrollItemsPartial from '@/pages/lib/partials/ScrollItemsPartial/ScrollItemsPartial.vue'
import GameAdvantagesItem from './GameAdvantagesItem/GameAdvantagesItem.vue'

export default {
  props: {
    currentGame: String
  },
  components: {
    GameAdvantagesItem,
    ScrollItemsPartial
  },
  computed: {
    content () {
      return {
        title: this.i18nSection(`gamepage${this.currentGame}AdvantageSectionTitle`),
        description: this.i18nSection(`gamepage${this.currentGame}AdvantageSectionDescription`)
      }
    },
    advantageItems () {
      return [
        {
          src: '/src/hand1.svg',
          title: this.i18nSection(`gamepage${this.currentGame}Advantage1ItemTitle`),
          text: this.i18nSection(`gamepage${this.currentGame}Advantage1ItemText`),
          isExist: true
        },
        {
          src: '/src/hand2.svg',
          title: this.i18nSection(`gamepage${this.currentGame}Advantage2ItemTitle`),
          text: this.i18nSection(`gamepage${this.currentGame}Advantage2ItemText`),
          isExist: true
        },
        {
          src: '/src/hand3.svg',
          title: this.i18nSection(`gamepage${this.currentGame}Advantage3ItemTitle`),
          text: this.i18nSection(`gamepage${this.currentGame}Advantage3ItemText`),
          isExist: true
        },
        {
          src: '/src/hand4.svg',
          title: this.i18nSection(`gamepage${this.currentGame}Advantage4ItemTitle`),
          text: this.i18nSection(`gamepage${this.currentGame}Advantage4ItemText`),
          isExist: false
        }
      ]
    }
  }
}
