import Accordion from './Accordion/Accordion.vue'
import TxButton from '@/components/lib/TxButton/TxButton.vue'

export default {
  props: {
    currentGame: String
  },
  components: {
    Accordion,
    TxButton
  },
  computed: {
    accordionContent () {
      return [
        {
          title: this.i18nSection(`gamepage${this.currentGame}Question1`),
          text: this.i18nSection(`gamepage${this.currentGame}Answer1`)
        },
        {
          title: this.i18nSection(`gamepage${this.currentGame}Question2`),
          text: this.i18nSection(`gamepage${this.currentGame}Answer2`)
        },
        {
          title: this.i18nSection(`gamepage${this.currentGame}Question3`),
          text: this.i18nSection(`gamepage${this.currentGame}Answer3`)
        }
      ]
    }
  },
  methods: {
    goToFaq () {
      this.$router.push({
        path: this.createLocalizedLink('/faq')
      })
    },
    createLocalizedLink (url) {
      if (url.startsWith('/private')) {
        return url
      }
      return this.getLanguageUrlPathPrefix() + url
    }
  }
}
