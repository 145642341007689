import { mapGetters } from 'vuex'
import ScrollItemsPartial from '@/pages/lib/partials/ScrollItemsPartial/ScrollItemsPartial.vue'
import TxButton from '@/components/lib/TxButton/TxButton.vue'
import Spinner from '@/components/lib/Spinner/Spinner.vue'
import GameArticleItem from './GameArticleItem/GameArticleItem.vue'

export default {
  props: {
    currentGame: String,
    articles: Array
  },
  components: {
    ScrollItemsPartial,
    GameArticleItem,
    TxButton,
    Spinner
  },
  computed: {
    ...mapGetters({
      language: 'language/language'
    }),
    isArticlesLoaded () {
      return this.articles !== null
    },
    content () {
      return {
        title: this.i18nSection(`gamepage${this.currentGame}ArticleSectionTitle`)
      }
    }
  },
  methods: {
    goToBlog () {
      this.$router.push({
        path: this.createLocalizedLink('/blog')
      })
    },
    createLocalizedLink (url) {
      if (url.startsWith('/private')) {
        return url
      }
      return this.getLanguageUrlPathPrefix() + url
    }
  }
}
