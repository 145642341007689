import { mapState } from 'vuex'
import ScrollItemsPartial from '@/pages/lib/partials/ScrollItemsPartial/ScrollItemsPartial.vue'
import GameTeamItem from './GameTeamItem/GameTeamItem.vue'

export default {
  props: {
    currentGame: String,
    members: Array
  },
  components: {
    GameTeamItem,
    ScrollItemsPartial
  },
  computed: {
    ...mapState({
    }),
    content () {
      return {
        title: this.i18nSection(`gamepage${this.currentGame}TeamSectionTitle`),
        description: this.i18nSection(`gamepage${this.currentGame}TeamSectionDescription`)
      }
    }
  },
  methods: {
    scrollItems () {
      const slider = document.querySelector('.slider')
      let isDown = false
      let startX
      let scrollLeft

      slider.addEventListener('mousedown', (e) => {
        isDown = true
        slider.classList.add('active')
        startX = e.pageX - slider.offsetLeft
        scrollLeft = slider.scrollLeft
      })
      slider.addEventListener('mouseleave', () => {
        isDown = false
        slider.classList.remove('active')
      })
      slider.addEventListener('mouseup', () => {
        isDown = false
        slider.classList.remove('active')
      })
      slider.addEventListener('mousemove', (e) => {
        if (!isDown) {
          return
        }
        e.preventDefault()
        const x = e.pageX - slider.offsetLeft
        const walk = (x - startX) * 3 // scroll-fast
        slider.scrollLeft = scrollLeft - walk
      })
    }
  }
}
