import { mapState, mapActions } from 'vuex'
import TwoColumnHeaderPartial from '@/pages/lib/partials/TwoColumnHeaderPartial/TwoColumnHeaderPartial.vue'
import TxButton from '@/components/lib/TxButton/TxButton.vue'

export default {
  props: {
    currentGame: String
  },
  components: {
    TwoColumnHeaderPartial,
    TxButton
  },
  data () {
    return {
      isBusySignIn: false
    }
  },
  computed: {
    ...mapState({
      passport: state => state.passport?.user
    }),
    gamepagePromoContent () {
      return {
        title: this.i18nSection(`gamepage${this.currentGame}PromoTitle`),
        description: this.i18nSection(`gamepage${this.currentGame}PromoDescription`),
        subtitle: this.i18nSection(`gamepage${this.currentGame}PromoSubtitle`)
      }
    }
  },
  methods: {
    ...mapActions({
      authInitiate: 'auth/initiate'
    }),
    async handleSignIn () {
      await this.authInitiate()
    }
  }
}
