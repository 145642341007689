import GameStatisticItem from './GameStatisticItem/GameStatisticItem.vue'
import { mapGetters } from 'vuex'

export default {
  props: {
    currentGame: String
  },
  components: {
    GameStatisticItem
  },
  data () {
    return {
      isShowVideo: false
    }
  },
  computed: {
    ...mapGetters({
      getExternalLinkObject: 'public/getExternalLinkObject'
    }),
    statisticItems () {
      return [
        {
          firstLineText: this.i18nSection(`gamepage${this.currentGame}GameStatistic1ItemTitle`),
          secondLineText: this.i18nSection(`gamepage${this.currentGame}GameStatistic1ItemText`)
        },
        {
          firstLineText: this.i18nSection(`gamepage${this.currentGame}GameStatistic2ItemTitle`),
          secondLineText: this.i18nSection(`gamepage${this.currentGame}GameStatistic2ItemText`)
        },
        {
          firstLineText: this.i18nSection(`gamepage${this.currentGame}GameStatistic3ItemTitle`),
          secondLineText: this.i18nSection(`gamepage${this.currentGame}GameStatistic3ItemText`)
        },
        {
          firstLineText: this.i18nSection(`gamepage${this.currentGame}GameStatistic4ItemTitle`),
          secondLineText: this.i18nSection(`gamepage${this.currentGame}GameStatistic4ItemText`)
        },
        {
          firstLineText: this.i18nSection(`gamepage${this.currentGame}GameStatistic5ItemTitle`),
          secondLineText: this.i18nSection(`gamepage${this.currentGame}GameStatistic5ItemText`)
        },
        {
          firstLineText: this.i18nSection(`gamepage${this.currentGame}GameStatistic6ItemTitle`),
          secondLineText: this.i18nSection(`gamepage${this.currentGame}GameStatistic6ItemText`)
        }
      ]
    }
  },
  methods: {
  }
}
