import { mapGetters, mapActions } from 'vuex'

import TxButton from '@/components/lib/TxButton/TxButton.vue'
import ScrollItemsPartial from '@/pages/lib/partials/ScrollItemsPartial/ScrollItemsPartial.vue'
import HowToItem from './HowToItem/HowToItem.vue'

export default {
  props: {
    currentGame: String
  },
  components: {
    TxButton,
    HowToItem,
    ScrollItemsPartial
  },
  data () {
    return {
      isBusyHandleSignUp: false
    }
  },
  computed: {
    ...mapGetters({
      config: 'config/config'
    }),
    content () {
      return {
        title: this.i18nSection(`gamepage${this.currentGame}AboutModelTitle`),
        asH2: true
      }
    },
    items () {
      return [
        {
          num: 1,
          title: this.i18nSection(`gamepage${this.currentGame}AboutModelOneItemTitle`),
          text: this.i18nSection(`gamepage${this.currentGame}AboutModelOneItemText`)
        },
        {
          num: 2,
          title: this.i18nSection(`gamepage${this.currentGame}AboutModelTwoItemTitle`),
          text: this.i18nSection(`gamepage${this.currentGame}AboutModelTwoItemText`)
        },
        {
          num: 3,
          title: this.i18nSection(`gamepage${this.currentGame}AboutModelThreeItemTitle`),
          text: this.i18nSection(`gamepage${this.currentGame}AboutModelThreeItemText`)
        }
      ]
    }
  },
  methods: {
    ...mapActions({
      authInitiate: 'auth/initiate'
    }),
    scrollItems () {
      const slider = document.querySelector('.slider')
      let isDown = false
      let startX
      let scrollLeft

      slider.addEventListener('mousedown', (e) => {
        isDown = true
        slider.classList.add('active')
        startX = e.pageX - slider.offsetLeft
        scrollLeft = slider.scrollLeft
      })
      slider.addEventListener('mouseleave', () => {
        isDown = false
        slider.classList.remove('active')
      })
      slider.addEventListener('mouseup', () => {
        isDown = false
        slider.classList.remove('active')
      })
      slider.addEventListener('mousemove', (e) => {
        if (!isDown) {
          return
        }
        e.preventDefault()
        const x = e.pageX - slider.offsetLeft
        const walk = (x - startX) * 3 // scroll-fast
        slider.scrollLeft = scrollLeft - walk
      })
    },
    async handleSignUp () {
      await this.authInitiate({ mode: 'signup' })
    }
  }
}
