import { mapGetters } from 'vuex'
import { CachedImageModel } from 'src/models'
import format from 'date-fns/format'

import Spinner from '@/components/lib/Spinner/Spinner.vue'

export default {
  props: {
    article: Object
  },
  components: {
    Spinner
  },
  computed: {
    ...mapGetters({
      config: 'config/config'
    }),
    blogUrlPrefix () {
      return `${this.config.self.url}${this.getLanguageUrlPathPrefix()}/blog`
    },
    articleUrl () {
      return `${this.blogUrlPrefix}/${this.article.metainfo.url}`
    }
  },
  methods: {
    formatDate (dateString) {
      return format(new Date(dateString), 'dd.MM.yyyy')
    },
    getImageUrl (image) {
      const imageModel = CachedImageModel.fromServerModel(image, {
        baseUrl: this.config.publicService.imageUrl
      })
      return imageModel.url
    }
  }
}
