import WithBackgroundImagePartial from '@/pages/lib/partials/WithBackgroundImagePartial/WithBackgroundImagePartial.vue'

export default {
  props: {
    currentGame: String
  },
  components: {
    WithBackgroundImagePartial
  },
  computed: {
    title () {
      return this.i18nSection(`gamepage${this.currentGame}Title`)
    },
    items () {
      return [
        {
          itemTitle: this.i18nSection(`gamepage${this.currentGame}InfoTitle`),
          itemText: this.i18nSection(`gamepage${this.currentGame}InfoText`),
          titleAsH2: true,
          itemImg: '/src/axie-logo.png'
        }
      ]
    }
  }
}
