import { render, staticRenderFns } from "./GameArticleSection.htm?vue&type=template&id=2ed786fd&scoped=true&"
import script from "./GameArticleSection.js?vue&type=script&lang=js&"
export * from "./GameArticleSection.js?vue&type=script&lang=js&"
import style0 from "./GameArticleSection.scss?vue&type=style&index=0&id=2ed786fd&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ed786fd",
  null
  
)

export default component.exports