import PartnersItem from './PartnersItem/PartnersItem.vue'

export default {
  props: {
    currentGame: String,
    partners: Array
  },
  components: {
    PartnersItem
  }
}
