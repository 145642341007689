import { mapActions, mapGetters, mapState } from 'vuex'
import NavBar from '@/pages/lib/sections/NavBar/NavBar.vue'
import PublicFooter from '@/pages/lib/sections/PublicFooter/PublicFooter.vue'
import TxButton from '@/components/lib/TxButton/TxButton.vue'

import GamePagePromoSection from './GamePagePromoSection/GamePagePromoSection.vue'
import GamePageInfoSection from './GamePageInfoSection/GamePageInfoSection.vue'
import GameTeamSection from './GameTeamSection/GameTeamSection.vue'
import GameAdvantagesSection from './GameAdvantagesSection/GameAdvantagesSection.vue'
import GamePartnersSection from './GamePartnersSection/GamePartnersSection.vue'
import GameStatisticSection from './GameStatisticSection/GameStatisticSection.vue'
import GameAboutModelSection from './GameAboutModelSection/GameAboutModelSection.vue'
import GameArticleSection from './GameArticleSection/GameArticleSection.vue'
import TimeToPlaySection from './TimeToPlaySection/TimeToPlaySection.vue'
import FaqSection from './FaqSection/FaqSection.vue'

export default {
  components: {
    NavBar,
    GamePagePromoSection,
    GamePageInfoSection,
    GamePartnersSection,
    GameTeamSection,
    GameAdvantagesSection,
    GameStatisticSection,
    GameAboutModelSection,
    GameArticleSection,
    TimeToPlaySection,
    PublicFooter,
    TxButton,
    FaqSection
  },
  data () {
    return {
      game: 'Axie'
    }
  },
  computed: {
    ...mapGetters({
      config: 'config/config'
    }),
    ...mapState({
      gameMembers: state => state.games.gameMembers,
      gamePartners: state => state.games.gamePartners,
      gameArticlesLoaded: state => state.games.gameArticles.isLoaded,
      gameArticles: state => state.games.gameArticles.value.articles
    })
  },
  async serverPrefetch () {
    try {
      await Promise.all([
        this.loadGameMembers(this.game),
        this.loadGamePartners(this.game),
        this.loadGameArticles(this.game)
      ])
    } catch (e) {
      throw new Error('Cannot load data')
    }
  },
  async mounted () {
    if (!this.gameArticlesLoaded || this.gameMembers.length === 0 || this.gamePartners.length === 0) {
      await Promise.all([
        this.loadGameMembers(this.game),
        this.loadGamePartners(this.game),
        this.loadGameArticles(this.game)
      ])
    }
  },
  methods: {
    ...mapActions({
      loadGameMembers: 'games/loadGameMembers',
      loadGamePartners: 'games/loadGamePartners',
      loadGameArticles: 'games/loadGameArticles'
    }),
    handleCgu () {
      window.open('https://www.cgu.io/', '_blank')
    },
    createLocalizedLink (url) {
      if (url.startsWith('/private')) {
        return url
      }
      return this.getLanguageUrlPathPrefix() + url
    }
  }
}
